import 'react-dates/lib/css/_datepicker.css'
import './style.css'

import {
  Button,
  Checkbox,
  Dropdown,
  DropdownProps,
  Grid,
  Header,
  Label,
  Segment,
  Statistic,
} from 'semantic-ui-react'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import React, { useEffect, useState } from 'react'
import { component as Refresh, selectors as refreshSelectors, stateIndexes } from '../../refresh'
import {
  dateStringToMoment,
  getButtonDisabled,
  getCheckBoxTitle,
  momentToDateString,
  stringSort,
} from './helpers'
import { itemsIfEvent, itemsSort } from '../../refresh/helpers'
import { useAppDispatch, useAppSelector } from '../../store'

import DefaultTheme from 'react-dates/lib/theme/DefaultTheme'
import Prompt from '../prompt'
import { RestoreScrollPosition } from '../../ui'
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import aphroditeInterface from 'react-with-styles-interface-aphrodite'
import debug from 'debug'
import { objHasKey } from '../registrants/helpers'
import { stateIndexType } from '../../refresh/actions'
import { actions as topMenuActions } from '../topmenu'
import { actions as websocketActions } from '../../websocket'

debug.enable('settings/index:*')
// const log = debug('settings/index:log')
// const info = debug('settings/index:info')
// const error = debug('settings/index:error')

ThemedStyleSheet.registerInterface(aphroditeInterface)
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    zIndex: 4,
    font: {
      ...DefaultTheme.reactDates.font,
      input: {
        ...DefaultTheme.reactDates.font.input,
        size_small: '1em',
      },
    },
    spacing: {
      ...DefaultTheme.reactDates.spacing,
      // displayTextPaddingTop_small: 7,
      // displayTextPaddingBottom_small: 3,
    },
  },
})

let currentAppVersion: string // stored current app version

const Settings = () => {
  const dispatch = useAppDispatch()

  const allItems = useAppSelector(state => refreshSelectors.allItems(state))
  const users = useAppSelector(state => refreshSelectors.users(state))
  const registrants = useAppSelector(state => refreshSelectors.registrants(state))
  const propsSettings = useAppSelector(state => refreshSelectors.settings(state))
  const refreshStatus = useAppSelector(state => refreshSelectors.refreshStatus(state))

  const [settings, setSettings] = useState<settingsType>(propsSettings)
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)

  useEffect(() => {
    dispatch(topMenuActions.updateTopMenuContent(<div className="top-menu-title">Settings</div>))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateEventsDates = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment
    endDate: moment.Moment
  }) => {
    const newSettings = {
      ...settings,
      events: {
        ...settings.events,
        start_date: momentToDateString(startDate),
        end_date: momentToDateString(endDate),
      },
    }
    setSettings(newSettings)
  }

  const updateItemsDeselected = (item: DbItemType, checked: boolean | undefined) => {
    const newSettings: settingsType = {
      ...settings,
      itemsDeselected: {
        ...settings.itemsDeselected,
      },
    }

    if (checked) {
      delete newSettings.itemsDeselected[item.slug]
      item.product && delete newSettings.itemsDeselected[item.product.id]
      // special treatment for summer/resolution swing 2018 and earlier
      Object.values(allItems).forEach((i: DbItemType) => {
        if (item.slug !== i.slug && i.slug.match(new RegExp(item.slug))) {
          delete newSettings.itemsDeselected[i.slug]
          i.product && delete newSettings.itemsDeselected[i.product.id]
        }
      })
    } else {
      newSettings.itemsDeselected[item.slug] = true
      item.product && (newSettings.itemsDeselected[item.product.id] = true)
      // special treatment for summer/resolution swing 2018 and earlier
      Object.values(allItems).forEach((i: DbItemType) => {
        if (item.slug !== i.slug && i.slug.match(new RegExp(item.slug))) {
          newSettings.itemsDeselected[i.slug] = true
          i.product && (newSettings.itemsDeselected[i.product.id] = true)
        }
      })
    }
    setSettings(newSettings)
  }

  const updateUiSettings = (
    ui: settingsTypeKeys,
    key: settingsTypeKeys,
    checked: boolean | undefined
  ) => {
    const newSettings = {
      ...settings,
      [ui]: {
        ...settings[ui],
        [key]: checked,
      },
    }
    setSettings(newSettings)
  }

  const cancelSettingsChanges = (keys: settingsTypeKeys | Array<settingsTypeKeys>) => {
    // restore settings
    const keyArray = Array.isArray(keys) ? keys : [keys]
    const restoredSettings = Object.fromEntries(
      Object.entries(propsSettings).filter(([key]) => keyArray.includes(key as settingsTypeKeys))
    )
    setSettings({ ...settings, ...restoredSettings })
  }

  const saveSettingsChanges = (keys: settingsTypeKeys | Array<settingsTypeKeys>) => {
    const keyArray = Array.isArray(keys) ? keys : [keys]
    const newSettings = Object.fromEntries(
      Object.entries(settings).filter(([key]) => keyArray.includes(key as settingsTypeKeys))
    )
    const msg = { cmd: 'update settings', data: { settings: { ...propsSettings, ...newSettings } } }
    dispatch(websocketActions.msgSend(msg))
  }

  const updateSkipUnusedUsersDropdown = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    const newSettings: settingsType = {
      ...settings,
      uiUsers: {
        ...settings.uiUsers,
        skipUnusedUsersOptions: {
          ...settings.uiUsers.skipUnusedUsersOptions,
          defaultValue: value as uiUsersSkipUnusedUsersOptionValuesType,
        },
      },
    }
    setSettings(newSettings)
  }

  const { events, itemsDeselected, uiRegistrants, uiItems, uiUsers } = settings

  const [cancelEventsButtonDisabled, saveEventsButtonDisabled] = getButtonDisabled(
    { events },
    propsSettings
  )
  const [cancelItemsButtonDisabled, saveItemsButtonDisabled] = getButtonDisabled(
    { itemsDeselected, uiItems },
    propsSettings
  )
  const [cancelUiRegistrantsButtonDisabled, saveUiRegistrantsButtonDisabled] = getButtonDisabled(
    { uiRegistrants },
    propsSettings
  )
  const [cancelUiUsersButtonDisabled, saveUiUsersButtonDisabled] = getButtonDisabled(
    { uiUsers },
    propsSettings
  )
  const itemsOrRegistrantsRefreshing = !!`${refreshStatus.items}${refreshStatus.users}`.match(
    /refreshing/
  )

  const selectedItems = Object.values(allItems)
    .filter(itemsIfEvent(propsSettings))
    .sort(itemsSort) as Array<DbItemType>

  if (!currentAppVersion) currentAppVersion = propsSettings.app.version! // only update once

  return (
    <div>
      <RestoreScrollPosition />
      <Grid container style={{ marginTop: '5rem', marginBottom: '2rem' }}>
        <Prompt
          when={
            !cancelEventsButtonDisabled ||
            !cancelItemsButtonDisabled ||
            !cancelUiRegistrantsButtonDisabled ||
            !cancelUiUsersButtonDisabled
          }
          message="You have unsaved settings changes. Are you sure you want to leave?"
        />
        <Grid.Row style={{ paddingTop: '0.5rem' }}>
          <Grid.Column>
            {!currentAppVersion ? null : (
              <div style={{ textAlign: 'center', fontSize: '14px' }}>
                {`App version: ${currentAppVersion}`}
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={2} tablet={2} only="computer tablet" />
          <Grid.Column computer={12} tablet={12} mobile={16}>
            <Segment.Group horizontal widths={2} className="get-refresh-data-segment">
              <Segment attached="top" className="get-all-data-segment">
                <div className="refresh-div">
                  <Refresh
                    componentType="circular button"
                    idx={stateIndexes.REFRESH_IDX_BUTTON_GET_DATA as stateIndexType}
                    iconProps={{ name: 'upload', size: 'big', bordered: true }}
                    popupProps={{ offset: [0, 0], size: 'huge' }}
                    buttonName="Get Data"
                  />
                </div>
                <Header
                  size="large"
                  style={{ marginLeft: '1em', marginTop: '0' }}
                  className="mobile screen-hidden"
                >
                  GET ALL DATA
                </Header>
              </Segment>
              <Segment attached="top" className="refresh-all-data-segment">
                <div className="refresh-div">
                  <Refresh
                    componentType="circular button"
                    idx={stateIndexes.REFRESH_IDX_BUTTON_REFRESH as stateIndexType}
                    iconProps={{ size: 'big', bordered: true }}
                    popupProps={{ offset: [0, 0], size: 'huge' }}
                  />
                </div>
                <Header
                  size="large"
                  style={{ marginLeft: '1em', marginTop: '0' }}
                  className="mobile screen-hidden"
                >
                  REFRESH ALL DATA
                </Header>
              </Segment>
            </Segment.Group>
            <Segment
              attached
              textAlign="center"
              className="datepicker-segment"
              disabled={itemsOrRegistrantsRefreshing}
            >
              <Header size="medium" style={{ marginBottom: '0', marginTop: '0.5em' }}>
                Date Range:
              </Header>
              <div className="datepicker">
                <DateRangePicker
                  noBorder
                  keepOpenOnDateSelect
                  showDefaultInputIcon
                  hideKeyboardShortcutsPanel
                  startDate={dateStringToMoment(events.start_date!)}
                  startDateId="startDate"
                  endDate={dateStringToMoment(events.end_date!)}
                  endDateId="endDate"
                  onDatesChange={updateEventsDates}
                  focusedInput={focusedInput}
                  onFocusChange={setFocusedInput}
                  isOutsideRange={() => false}
                  minimumNights={0}
                  numberOfMonths={1}
                />
              </div>
              <div style={{ marginTop: '1em' }}>
                <Button.Group fluid size="big" widths="2" style={{ maxWidth: '26rem' }}>
                  <Button
                    onClick={() => cancelSettingsChanges('events')}
                    disabled={cancelEventsButtonDisabled}
                  >
                    Revert
                  </Button>
                  <Button.Or />
                  <Button
                    positive
                    onClick={() => saveSettingsChanges('events')}
                    disabled={saveEventsButtonDisabled}
                  >
                    Save
                    <div
                      style={{ display: 'inline' }}
                      className="mobile screen-hidden tablet screen-hidden"
                    >
                      {' '}
                      Changes
                    </div>
                  </Button>
                </Button.Group>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={2} tablet={2} only="computer tablet" />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={1} tablet={1} only="computer tablet" />
          <Grid.Column computer={14} tablet={14} mobile={16} verticalAlign="middle">
            <Segment attached="top" textAlign="center" className="items-segment">
              <Label attached="top right" size="large">
                <Refresh
                  componentType="circular button"
                  idx={stateIndexes.REFRESH_IDX_ITEMS as stateIndexType}
                  refreshEndpoints={[{ endpoint: 'refresh/items' }]}
                  iconProps={{ size: 'big' }}
                  popupProps={{ offset: [14, 12], size: 'huge', position: 'bottom right' }}
                />
              </Label>
              <Statistic horizontal size="huge">
                <Statistic.Value>{selectedItems.length}</Statistic.Value>
                <Statistic.Label style={{ fontSize: '200%' }}>
                  Item
                  {selectedItems.length > 1 ? 's' : ''}
                </Statistic.Label>
              </Statistic>
            </Segment>
            <Segment
              attached
              textAlign="center"
              loading={!!refreshStatus.items && !!refreshStatus.items.match(/refreshing/)}
              style={{ paddingLeft: '2em', paddingRight: '2em' }}
            >
              <Header size="large" style={{ marginBottom: '0', marginTop: '0.5em' }}>
                Items to display:
              </Header>
              <div>
                <div
                  style={{
                    textAlign: 'left',
                    marginTop: '0.75rem',
                    marginBottom: '0.5rem',
                    display: 'inline-block',
                  }}
                >
                  {selectedItems
                    .filter(item => getCheckBoxTitle(item)?.match(/need to be loaded/))
                    .map(item => (
                      <div
                        key={item.slug}
                        data-slug={item.slug}
                        style={{ margin: '0.15em 0' }}
                        className="settings-checkbox-placeholder"
                      >
                        {getCheckBoxTitle(item)}
                      </div>
                    ))}
                  {selectedItems
                    .filter(item => !getCheckBoxTitle(item)?.match(/need to be loaded/))
                    .sort((a, b) => stringSort(a.slug.toLowerCase(), b.slug.toLowerCase()))
                    .map(item => (
                      <div
                        key={item.slug}
                        data-slug={item.slug}
                        style={{ margin: '0.15em 0' }}
                        className="settings-checkbox"
                      >
                        <Checkbox
                          label={getCheckBoxTitle(item)}
                          checked={!itemsDeselected || !itemsDeselected[item.slug]}
                          onChange={(evt, data) => updateItemsDeselected(item, data.checked)}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div
                style={{
                  textAlign: 'left',
                  marginTop: '0.75rem',
                  marginBottom: '1.5rem',
                  display: 'inline-block',
                }}
              >
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Show items without events"
                    checked={uiItems.itemsWithoutEvents}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiItems',
                        'itemsWithoutEvents' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Show item descriptions"
                    checked={uiItems.itemsShowDescription}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiItems',
                        'itemsShowDescription' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Show bundled items"
                    checked={uiItems.itemsPartOfBundle}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiItems',
                        'itemsPartOfBundle' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <Button.Group fluid size="big" widths="2" style={{ maxWidth: '26rem' }}>
                  <Button
                    onClick={() => cancelSettingsChanges(['itemsDeselected', 'uiItems'])}
                    disabled={cancelItemsButtonDisabled}
                  >
                    Revert
                  </Button>
                  <Button.Or />
                  <Button
                    positive
                    onClick={() => saveSettingsChanges(['itemsDeselected', 'uiItems'])}
                    disabled={saveItemsButtonDisabled}
                  >
                    Save
                    <div
                      style={{ display: 'inline' }}
                      className="mobile screen-hidden tablet screen-hidden"
                    >
                      {' '}
                      Changes
                    </div>
                  </Button>
                </Button.Group>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={1} tablet={1} only="computer tablet" />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={1} tablet={1} only="computer tablet" />
          <Grid.Column computer={7} tablet={7} mobile={16}>
            <Segment attached="top" textAlign="center" className="registrants-segment">
              <Label attached="top right" size="large" style={{ zIndex: 102 }}>
                <Refresh
                  componentType="circular button"
                  idx={stateIndexes.REFRESH_IDX_REGISTRANTS as stateIndexType}
                  refreshEndpoints={[
                    { endpoint: 'refresh/registrants', query: 'filterByItems=true' },
                  ]}
                  iconProps={{ size: 'big' }}
                  popupProps={{ offset: [14, 12], size: 'huge', position: 'bottom right' }}
                  buttonProps={{ disabled: itemsOrRegistrantsRefreshing }}
                />
              </Label>
              <Segment
                basic
                className="registrants-basic-segment"
                loading={
                  !!refreshStatus.registrants && !!refreshStatus.registrants.match(/refreshing/)
                }
              >
                <Statistic size="huge">
                  <Statistic.Label style={{ fontSize: '200%' }}>
                    Registrant
                    {Object.keys(registrants || {}).length > 1 ? 's' : ''}
                  </Statistic.Label>
                  <Statistic.Value>{Object.keys(registrants || {}).length}</Statistic.Value>
                </Statistic>
              </Segment>
            </Segment>
            <Segment
              attached
              textAlign="center"
              style={{ paddingLeft: '2em', paddingRight: '2em' }}
            >
              <div
                style={{
                  textAlign: 'left',
                  marginTop: '0.75rem',
                  marginBottom: '1.5rem',
                  display: 'inline-block',
                }}
              >
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Separate checked-in registrants"
                    checked={uiRegistrants.separateCheckedIn}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiRegistrants',
                        'separateCheckedIn' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Clear search on check-in"
                    checked={uiRegistrants.clearSearchOnCheckIn}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiRegistrants',
                        'clearSearchOnCheckIn' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Hide email addresses"
                    checked={uiRegistrants.hideEmailAddress}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiRegistrants',
                        'hideEmailAddress' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Hide vaccination status"
                    checked={uiRegistrants.hideVaccinationStatus}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiRegistrants',
                        'hideVaccinationStatus' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Hide orders when cancelled"
                    checked={uiRegistrants.hideOrdersWhenCancelled}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiRegistrants',
                        'hideOrdersWhenCancelled' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Hide cash order status when paid"
                    checked={uiRegistrants.hideOrderStatusWhenPaid}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiRegistrants',
                        'hideOrderStatusWhenPaid' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                </div>
                {!objHasKey(uiRegistrants, 'pollPlacements') ? (
                  ''
                ) : (
                  <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                    <Checkbox
                      label="Poll placements spreadsheet every minute"
                      checked={uiRegistrants.pollPlacements}
                      onChange={(evt, data) =>
                        updateUiSettings(
                          'uiRegistrants',
                          'pollPlacements' as settingsTypeKeys,
                          data.checked
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div>
                <Button.Group fluid size="big" widths="2" style={{ maxWidth: '26rem' }}>
                  <Button
                    onClick={() => cancelSettingsChanges('uiRegistrants')}
                    disabled={cancelUiRegistrantsButtonDisabled}
                  >
                    Revert
                  </Button>
                  <Button.Or />
                  <Button
                    positive
                    onClick={() => saveSettingsChanges('uiRegistrants')}
                    disabled={saveUiRegistrantsButtonDisabled}
                  >
                    Save
                    <div
                      style={{ display: 'inline' }}
                      className="mobile screen-hidden tablet screen-hidden"
                    >
                      {' '}
                      Changes
                    </div>
                  </Button>
                </Button.Group>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} only="mobile">
            <div style={{ height: '28px' }} />
          </Grid.Column>
          <Grid.Column computer={7} tablet={7} mobile={16}>
            <Segment attached="top" textAlign="center" className="users-segment">
              <Label attached="top right" size="large" style={{ zIndex: 102 }}>
                <Refresh
                  componentType="circular button"
                  idx={stateIndexes.REFRESH_IDX_USERS as stateIndexType}
                  refreshEndpoints={[{ endpoint: 'refresh/users' }]}
                  iconProps={{ size: 'big' }}
                  popupProps={{ offset: [14, 12], size: 'huge', position: 'bottom right' }}
                />
              </Label>
              <Segment
                basic
                className="users-basic-segment"
                loading={!!refreshStatus.users && !!refreshStatus.users.match(/refreshing/)}
              >
                <Statistic size="huge">
                  <Statistic.Label style={{ fontSize: '200%' }}>
                    User
                    {Object.keys(users || {}).length > 1 ? 's' : ''}
                  </Statistic.Label>
                  <Statistic.Value>{Object.keys(users || {}).length}</Statistic.Value>
                </Statistic>
              </Segment>
            </Segment>
            <Segment
              attached
              textAlign="center"
              style={{ paddingLeft: '2em', paddingRight: '2em' }}
            >
              <div
                style={{
                  textAlign: 'left',
                  marginTop: '0.75rem',
                  marginBottom: '1.5rem',
                  display: 'inline-block',
                }}
              >
                <div className="settings-checkbox" style={{ margin: '0.15em 0' }}>
                  <Checkbox
                    label="Skip users unused"
                    checked={uiUsers.skipUnusedUsers}
                    onChange={(evt, data) =>
                      updateUiSettings(
                        'uiUsers',
                        'skipUnusedUsers' as settingsTypeKeys,
                        data.checked
                      )
                    }
                  />
                  <Dropdown
                    className="skip-unused-users-dropdown"
                    inline
                    onChange={updateSkipUnusedUsersDropdown}
                    options={uiUsers.skipUnusedUsersOptions?.options}
                    value={uiUsers.skipUnusedUsersOptions?.defaultValue}
                  />
                </div>
              </div>
              <div>
                <Button.Group fluid size="big" widths="2" style={{ maxWidth: '26rem' }}>
                  <Button
                    onClick={() => cancelSettingsChanges('uiUsers')}
                    disabled={cancelUiUsersButtonDisabled}
                  >
                    Revert
                  </Button>
                  <Button.Or />
                  <Button
                    positive
                    onClick={() => saveSettingsChanges('uiUsers')}
                    disabled={saveUiUsersButtonDisabled}
                  >
                    Save
                    <div
                      style={{ display: 'inline' }}
                      className="mobile screen-hidden tablet screen-hidden"
                    >
                      {' '}
                      Changes
                    </div>
                  </Button>
                </Button.Group>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={1} tablet={1} only="computer tablet" />
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default Settings
